import '@/css/main.styl';
import { createApp } from 'vue';
import scrollToElement from 'scroll-to-element';
import AnimatedReferences from "./components/AnimatedReferences.vue";
import AnimatedSymbol from "./components/AnimatedSymbol.vue";


function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

if(isTouchDevice()) {
    document.documentElement.classList.add('touch');
}

const app = createApp({
    components: {
        AnimatedReferences,
        AnimatedSymbol
    },

    data() {
        return {
            showMobileMenu: false
        }
    },
    
    mounted() {


    },

    methods: {
        scrollTo(e) {
            scrollToElement(e.target.hash, {
                duration: 1600,
                ease: 'inOutCube',
                offset: -100
            });
        },

    }
});


const vm = app.mount("#site");

console.log(import.meta.hot);
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}



